export default defineNuxtRouteMiddleware(async (to, from) => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()

  const { $UserService, $sessionStorage } = useNuxtApp()
  const search = to.query
  if (search.type && search.type === 'app') {
    mainStore.setIsLoadedFromApp(true)

    setPageLayout('empty')
  }

  if (search.accessToken) {
    let user = { id: search.userId }
    // set the token and user id so that we can request user data
    await authStore.login({ token: search.accessToken, user })

    user = await $UserService.getUser(search.userId)

    // set the user data
    await authStore.login({ token: search.accessToken, user })

    if (!import.meta.env.SSR) {
      $sessionStorage.setItem('riser_accessToken', search.accessToken)
      $sessionStorage.setItem('riser_userId', search.userId)
    }
  }

  // persisting type = app query across pages
  if (from && from.query && from.query.type === 'app' && !to.query.type) {
    return navigateTo({ path: to.path, query: { ...to.query, type: 'app' } })
  }
})
